import React from "react";
import ContentCollapsiblePanel from "components/Common/content-collapsible-panel";

const NswFoodSafetySupervisorCertificate = () => {
  return (
    <>
      <ContentCollapsiblePanel
        openSection={-1}
        sections={[
          {
            title: "About the Food Safety Supervisor",
            content: (
              <>
                <p>
                  This Food Safety Supervisor course prepares you with an
                  extensive understanding of the role of a Food Safety
                  Supervisor in NSW.
                </p>
                <p>
                  Owners of registered food businesses must ensure those who
                  handle food within their business handle food safely. The
                  owner must nominate a Food Safety Supervisor, who has the
                  responsibility of recognising, preventing and alleviating the
                  hazards associated with food handling in your business. Under{" "}
                  <strong>FSANZ Standard&nbsp;3.2.2A</strong>, all Australian
                  businesses that serve food must have one or more qualified
                  Food Safety Supervisor. A Food Safety Supervisor is someone
                  who has the appropriate Food Safety training to supervise Food
                  Safety in a business. Food Safety Supervisors are mainly
                  responsible for:
                </p>
                <ul>
                  <li>Supervising Food Handling staff</li>
                  <li>Maintaining the Food Safety Program</li>
                  <li>
                    Ensuring safe food handling practices across the business
                  </li>
                </ul>
                <p>
                  All retail, hospitality and food service businesses need to
                  appoint at least one Food Safety Supervisor (FSS) per premises
                  if food they prepare and serve is:
                </p>
                <ul>
                  <li>Ready-to-eat.</li>
                  <li>
                    Potentially hazardous, that is, needs temperature control.
                  </li>
                  <li>
                    NOT sold and served in the supplier's original package.
                  </li>
                </ul>

                <p>
                  Broadly, this includes all businesses selling potentially
                  hazardous food to the public that are not{" "}
                  <a
                    href="https://www.foodauthority.nsw.gov.au/node/1406"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    licensed
                  </a>{" "}
                  by the Food Authority, such as: cafes, restaurants, quick
                  service venues, takeaway shops, pubs and clubs with food
                  service areas, mobile vendors, home-based businesses,
                  caterers, motels, and supermarkets selling potentially
                  hazardous food (such as hot-chickens). It also includes
                  outlets that sell food through third party ordering apps.
                </p>
                <p>
                  Children&rsquo;s services that provide meals as part of their
                  operation now are required to comply with new Food Safety
                  requirements, introduced under Standard 3.2.2A of the Food
                  Standards Code.
                </p>
                <p>
                  <a
                    href="https://www.foodauthority.nsw.gov.au/media/8826"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Click here for the Food Safety requirements for
                    children&rsquo;s services in NSW.
                  </a>
                </p>
              </>
            ),
          },
          {
            title: "How do I get my certificate?",
            content: (
              <>
                <p>
                  It's as easy as following these 3 steps for the quickest,
                  cheapest and most convenient way to get your NSW Food Safety
                  Supervisor Certificate:
                </p>
                <ol>
                  <li>Select the NSW $179 Food Safety Supervisor Course.</li>
                  <li>
                    Make payment and enter the postal address where your NSW
                    Food Safety Supervisor Certificate will be express posted.
                  </li>
                  <li>Begin enrolment and complete online course.</li>
                </ol>
                <p>Total course fee: $179</p>
                <p>
                  With Food Safety Education there are no hidden fees or
                  charges.
                </p>
                <p>
                  Upon completion of the course, you will receive the NSW Food
                  Safety Supervisor Certificate (HARD COPY) this will be
                  expressed posted to you PLUS a Nationally Accredited Statement
                  of Attainment that is recognised in every other State of
                  Australia, which you can download straight away!
                </p>
                <p>That&rsquo;s right you will get two certificates!</p>
              </>
            ),
          },
          {
            title: "Breakdown of Costs",
            content: (
              <>
                <ul>
                  <li>
                    $139 Nationally Accredited Certificate - This can be
                    downloaded automatically on the successful completion of
                    your course.
                  </li>
                  <li>
                    $30 NSW Food Safety Supervisor Certificate - This is what
                    the NSW Food Authority charge us!
                  </li>
                  <li>
                    $10 Express Postage, Administration & Handling. This ensures
                    you will receive your certificate within 5 to 7 business
                    days via express post after you have successfully completed
                    the course.
                  </li>
                </ul>
              </>
            ),
          },
          {
            title: "Which category does my food business comply with?",
            content: (
              <>
                <p>
                  Food service, catering and retail businesses must comply with
                  Standard 3.2.2A, based on whether they are classified as
                  category one or category two businesses (see below).
                </p>
                <ul>
                  <li>
                    Category one (higher risk) businesses must implement all
                    three management tools.
                  </li>
                  <li>
                    Category two businesses must have a Food Safety Supervisor
                    and trained food handlers.
                  </li>
                </ul>
                <p>
                  These requirements are in place because unpackaged,
                  potentially hazardous food that is ready to eat is high risk
                  and needs careful handling to keep it safe.
                </p>

                <p>
                  <strong>Category one business</strong>
                </p>
                <ul>
                  <li>
                    A caterer or food service business that processes unpackaged
                    potentially hazardous food into food that is both
                    ready-to-eat and potentially hazardous food.
                  </li>
                  <li>
                    The food is then served to a consumer to eat without any
                    further processing.
                  </li>
                </ul>
                <p>
                  <strong>Category two business</strong>
                </p>
                <ul>
                  <li>
                    A retailer of potentially hazardous, ready-to-eat food,
                    where the food was handled unpackaged, but not made or
                    processed onsite (other than slicing, weighing, repacking,
                    reheating, or hot-holding).
                  </li>
                </ul>
                <p>
                  Sometimes it can be confusing to know which category your food
                  business falls under. We suggest talking with the NSW Food
                  Authority before you commence your training if you are unsure.
                </p>

                <p>
                  PH: <a href="tel:1300552406">1300&nbsp;552&nbsp;406</a>
                </p>
                <p>
                  Please click on this link below for more information on
                  Standard 3.2.2 A, this is very useful.
                </p>
                <p>
                  <a
                    href="https://www.foodauthority.nsw.gov.au/retail/standard-322a-food-safety-management-tools/standard-322a-frequently-asked-questions"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://www.foodauthority.nsw.gov.au/retail/standard-322a-food-safety-management-tools/standard-322a-frequently-asked-questions
                  </a>
                </p>
              </>
            ),
          },
          {
            title: "Course Summary",
            content: (
              <>
                <p>
                  The current national units of competency we deliver and are
                  accepted for the NSW Food Safety Supervisor Certificate are:
                </p>
                <p>
                  <strong>Two hospitality units:</strong>
                </p>
                <ul>
                  <li>SITXFSA005 – Use hygiene practices for food safety</li>
                  <li>
                    SITXFSA006 – Participate in safe food handling practices
                  </li>
                </ul>
                <p>
                  The units of competency we deliver incorporate the mandatory 4
                  key focus areas as determined by the NSW Food Authority. The
                  key focus areas were developed in response to common high risk
                  Food Safety issues in the retail and hospitality sectors and
                  include:
                </p>
                <ul>
                  <li>Allergen Management</li>
                  <li>Cleaning and Sanitising Practices</li>
                  <li>Safe Egg Handling</li>
                  <li>Food Act Offences</li>
                </ul>
                <p>
                  This Food Safety Supervisor course prepares you with an
                  extensive understanding of the role a Food Safety Supervisor
                  must play within the workplace. Our course is designed to be
                  as flexible and straight forward as possible! We pride
                  ourselves on our learning platform which is filled with many
                  short DVD's and visual images making this training course a
                  fast and effective way to obtain your Nationally Accredited
                  Certificate.
                </p>
                <p>
                  Open text responses are required to answer some questions and
                  case studies from the key focus areas and a third party form
                  needs to be completed from your nominated observer.
                </p>
                <p>
                  All our training courses allow students to complete their
                  course in their own time. That's right, there are no lock outs
                  and no penalties if you get any answers wrong!
                </p>
                <p>
                  On completion of your Food Safety Supervisor training, we also
                  offer you a downloadable course workbook. This online course
                  material is always updated so we encourage you to log back in
                  any time after your training and obtain any new Food Safety
                  information, this is especially helpful when training up new
                  staff and when council come around!
                </p>
              </>
            ),
          },
          {
            title: "NSW Food Authority Information",
            content: (
              <>
                <p>
                  You will need to ensure that the NSW Food Safety Supervisor
                  certificate is always kept on the premises, as council
                  inspectors may request to view the certificate during an
                  inspection.
                </p>
                <p>
                  For further Food Safety Supervisor information please{" "}
                  <a
                    href="https://www.foodauthority.nsw.gov.au/retail/fss-food-safety-supervisors"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    click here
                  </a>
                  .
                </p>
                <p>
                  Email:{" "}
                  <a
                    href="mailto:contact@foodauthority.nsw.gov.au"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    contact@foodauthority.nsw.gov.au
                  </a>
                  <br />
                  Phone:{" "}
                  <span style={{ fontSize: "1.6em" }}>
                    <a href="tel:1300552406">1300&nbsp;552&nbsp;406</a>
                  </span>
                </p>
              </>
            ),
          },
        ]}
      />
    </>
  );
};

export default NswFoodSafetySupervisorCertificate;
